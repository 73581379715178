var animOffset = 600,
	animQ,
	scrollThreshold = 0.65;
$(function(){
	// var animItems = $('section.gbl, .list-articles-item, article, .info, ul[class], .list-figures-item, .banner-footer');
	var animItems = $('section.gbl, .list-articles-item, article, .info, .list-figures-item, .banner-footer');
	animItems.each(function(){
		if(!$(this).attr('anim-fx') && $(this).offset().top > scrolld + scrollThreshold * winH){
			$(this).attr('anim-fx','fade');
		}
	});
	animItems = $('[anim-fx]');
	animItems.addClass('queued');
	var startItems = animItems.filter(function(){
		return $(this).offset().top < scrolld + scrollThreshold * winH
	});
	function revealItem(item){
		if(item.attr('anim-fx') == 'swell'){
			item.parent().height(item.height());
			item
				.hide()
				.removeClass('queued')
				.css({opacity:1})
				.show(animOffset);
		} else{
			item.removeClass('queued');
		}
	}
	animQ = setInterval(function(){
		var item = startItems.filter('.queued').first();
		if(item.length == 0){
			clearInterval(animQ)
		}
		revealItem(item);
	},animOffset*0.5);
	$('body').addClass('animated');
	body.on('scrolld',function(){
		animItems.each(function(){
			$(this).attr('data-top',$(this).offset().top)
		})
		animItems.filter('.queued').each(function(){
			if($(this).offset().top < scrolld + scrollThreshold * winH){
				revealItem($(this))
			}
		});
	});
});
