$(function () {
	var activeIndex = $('.active-tab').index(),
		contentlis = $('.floorplan-list li'),
		tabslis = $('floorplan-tabs li');
	
	// Show content of active tab on loads
	contentlis.eq(activeIndex).show();
  
	$('.floorplan-tabs').on('click', 'li', function (e) {
	  var current = $(e.currentTarget),
		  index = current.index();
	  
			tabslis.removeClass('active-tab');
			current.addClass('active-tab').siblings().removeClass('active-tab');
			contentlis.hide().eq(index).show();
	   });
});