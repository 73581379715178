$(function() {

    var listCards = $(".list-cards");

    listCards.each(function() {
        if ($(this).find("li.list-cards-item").length <= 3) {
            $(this).addClass("centre")
        }
    })


    $('.thumb-gallery').each(function(i){
		var id = "thumbGal_"+i;
		var main = $(this).find('ul.main'),
			thumbs = $(this).find('.thumbs'),
			$prev = $(this).find('.nav .prev'),
			$next = $(this).find('.nav .next');
		main.attr('id',id).flickity({
			imagesLoaded:true,
			lazyLoad: true,
			wrapAround:true,
			contain:true,
			pageDots:false,
			adaptiveHeight:true,
			prevNextButtons:false
		});
		thumbs.flickity({
			asNavFor: '#'+id,
			imagesLoaded:true,
			wrapAround:true,
			contain: true,
			pageDots: false,
			cellAlign:'left',
			prevNextButtons: false
		});
		$prev.click(function(){
			main.flickity('previous');
		})
		$next.click(function(){
			main.flickity('next');
		})

        var flkty = new Flickity('.thumb-gallery .main');
        var galleryStatus = document.querySelector('.gallery-status');

        function updateStatus() {
        var slideNumber = flkty.selectedIndex + 1;
        galleryStatus.textContent = '0' + slideNumber + ' / ' + '0' + flkty.slides.length;
        }
        updateStatus();

        flkty.on( 'select', updateStatus );
	});

    $('.cta.floorplan').click(function(){
        $('.floorplan-popup').show();
    })
    $('.floorplan-popup .close-popup').click(function(){
        $('.floorplan-popup').hide();
	})
})